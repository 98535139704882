export const ADD_EXAM_REQUEST = "ADD_EXAM_REQUEST";
export const ADD_EXAM_SUCCESS = "ADD_EXAM_SUCCESS";
export const ADD_EXAM_FAILURE = "ADD_EXAM_FAILURE";

export const GET_EXAM_REQUEST = "GET_EXAM_REQUEST";
export const GET_EXAM_SUCCESS = "GET_EXAM_SUCCESS";
export const GET_EXAM_FAILURE = "GET_EXAM_FAILURE";

export const UPDATE_EXAM_DATA_REQUEST = "UPDATE_EXAM_DATA_REQUEST";
export const UPDATE_EXAM_DATA_SUCCESS = "UPDATE_EXAM_DATA_SUCCESS";
export const UPDATE_EXAM_DATA_FAILURE = "UPDATE_EXAM_DATA_FAILURE";

export const CLEAR_MESSAGES = "CLEAR_MESSAGES";