import React from 'react'

function Footer() {
    return (
        <div className="text-center bg-dark text-white p-1">
            <p>Copyright Reserverd &copy;</p>
        </div>
    )
}

export default Footer
